import { handleLogout, handleLogoutChannelPartner } from "utils/helpers";

import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

import { routes } from "index/routes";
import { useAppContext } from "contexts/AppContext";

import * as Yup from "yup";
import { updateProfEmail } from "api/user";
import useQueryParams from "hooks/useQueryParam";
import { useUpdate } from "ahooks";
import useResumeStore from "store/store";

const emailSchema = Yup.object().shape({
  professionalEmail: Yup.string()
    .email("Invalid Email")
    .required("Email Required"),
});

export function useNavbar() {
  const [state, setState] = useState({
    isOpen: true,
    oldEmail: false,
    email: "",
    errors: {},
    modalVisible: false,
    key: 0,
  });

  const { handleMyPrepRole } = useResumeStore();

  const update = useUpdate();

  const { updateParams } = useQueryParams();

  const [isOpenHam, setOpenHam] = useState(false);
  const { state: appstate, setIsOpenProfileUpdateModal } = useAppContext();
  let navigate = useNavigate();
  const {
    userProfile: { isNew },
  } = appstate;

  const handleNavigation = () => {
    setState((prev) => ({
      ...prev,
      key: prev.key + 1,
    }));
  };
  const toggleDropdown = () => {
    setState((prev) => ({
      ...prev,
      isOpen: !state.isOpen,
    }));
  };

  function handleClickLink(e) {
    updateParams({ datasetId: null });
    update();
    switch (e.domEvent.target.textContent.toLowerCase()) {
      case "logout":
        appstate.userProfile.role === "channelpartner"
          ? handleLogoutChannelPartner()
          : handleLogout();
        break;
      case "help":
        navigate("/faq");
        break;
      case "preptips":
        navigate("/prep");
        break;
      case "my documents":
        navigate(routes.Dashboard);
        break;
      case "my profile":
        navigate(routes.ChannelPartnerProfile);
        break;
      case "my benefits":
        navigate(routes.ChannelPartnerBenefit);
        break;
      case "my contracts":
        navigate(routes.Contracts);
        break;
      case "my account":
        navigate(routes.MyAccount);
        break;
      default:
        navigate(e.domEvent.target.textContent.toLowerCase());
    }
  }
  function handleClickChannelPartnerLink(e) {
    updateParams({ datasetId: null });
    console.log(e.domEvent.target.textContent);
    switch (e.domEvent.target.textContent.toLowerCase()) {
      case "logout":
        handleLogoutChannelPartner();
        break;
      case "my profile":
        navigate(routes.ChannelPartnerProfile);
        break;
      case "my benefits":
        navigate(routes.ChannelPartnerBenefit);
        break;
      case "my contracts":
        navigate(routes.Contracts);
        break;
      case "authorize code":
        navigate(routes.Approval);
        break;
      case "register":
        navigate(routes.ChannelPartnerReg);
        break;
      default:
        // navigate(e.domEvent.target.textContent.toLowerCase());
        break;
    }
  }

  async function handleClick(key) {
    setOpenHam(false);
    switch (key) {
      case "myDocuments":
        setOpenHam(false);
        navigate(routes.Dashboard);
        break;
      case "templates":
        navigate(routes.Templates);
        break;
      case "pricing":
        navigate(routes.Pricing);
        break;
      case "faq":
        navigate(routes.Faq);
        break;
      case "myprep":
        handleMyPrepRole("6708fe19399087a87bee4ef8");
        navigate(`${routes.Myprep}/6708fe19399087a87bee4ef8`); //needs to be changed
        break;
      case "blogs":
        navigate(routes.blogs);
      case "emailUpdate":
        const emailUpdate = {
          professionalEmail: state.oldEmail
            ? appstate.userProfile.email
            : state.email,
        };
        try {
          await emailSchema.validate(emailUpdate, { abortEarly: false });
          console.log(emailUpdate);
          // const resp = await updateProfEmail(emailUpdate);
          // if (resp.data.data.acknowledged) {
          //   setIsOpenProfileUpdateModal(false);
          //   window.location.href = routes.Dashboard;
          // }
        } catch (err) {
          let errs = {};
          err.inner.forEach((er) => {
            errs[er.path] = er;
          });
          setState((prev) => ({ ...prev, errors: errs }));
        }

        break;

      case "closeModal":
        setState((prev) => {
          return {
            ...prev,
            modalVisible: false,
          };
        });
        break;
      case "openModal":
        setState((prev) => {
          return {
            ...prev,
            modalVisible: true,
          };
        });
        break;
      default:
        break;
    }
  }
  function onChange(key, e) {
    switch (key) {
      case "checkbox":
        setState((prev) => ({
          ...prev,
          oldEmail: e.target.checked,
        }));
        break;
      case "email":
        setState((prev) => ({
          ...prev,
          email: state.oldEmail ? appstate.userProfile.email : e.target.value,
        }));
        break;
      default:
        break;
    }
  }

  useEffect(() => {
    setState((prev) => ({ ...prev, errors: {} }));
  }, [state.email]);
  return {
    state,
    handleClickLink,
    toggleDropdown,
    handleClick,
    onChange,
    isOpenHam,
    setOpenHam,
    handleClickChannelPartnerLink,
  };
}
