import { message } from "antd";

let isMessageDisplayed = false;

export const handleError = (error) => {
  // Prevent multiple messages for the same error
  if (isMessageDisplayed) return;

  isMessageDisplayed = true;

  if (error.code === "ECONNABORTED" || error.message.includes("timeout")) {
    // Specific handling for timeout errors
    message.error("Network Timeout: Please try again later.", 3);
  }
  //   else if (error.response) {
  //     // Errors from the server (4xx, 5xx responses)
  //     const { status, data } = error.response;
  //     if (status === 404) {
  //       message.error("Resource not found (404).", 3);
  //     } else if (status === 500) {
  //       message.error("Server error: Please try again later.", 3);
  //     } else {
  //       message.error(data?.message || "An unexpected error occurred.", 3);
  //     }
  //   } else if (error.request) {
  //     // No response from the server
  //     message.error("No response from server. Check your connection.", 3);
  //   } else {
  //     // Other errors
  //     message.error(error.message || "An error occurred.", 3);
  //   }

  // Reset the flag after 3 seconds (message duration)
  setTimeout(() => {
    isMessageDisplayed = false;
  }, 3000);
  return error;
};
