import React from "react";

import ErrorBoundary from "assets/images/errorBoundary.svg";
import Button from "components-shared/Button/Button";

export default function TimeoutError() {
  return (
    <div className="flex flex-col justify-center items-center gap-4 mt-8">
      <img className="w-[25em]" src={ErrorBoundary} alt="Error" />
      <span>Something Went Wrong</span>
      <Button
        onClick={() => {
          window.location.reload();
        }}
      >
        Try Again
      </Button>
    </div>
  );
}
