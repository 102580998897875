import { Button as AntButton, ConfigProvider } from "antd";
import { motion } from "framer-motion";
// import { HappyProvider } from "@ant-design/happy-work-theme";

export default function Button({ loading = false, ...props }) {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#3B8AE7",
        },
        components: {
          Button: {
            paddingInline: props.paddingInline,
            paddingBlock: props.paddingBlock,
            ...(props.green && {
              defaultBorderColor: "#0CBF42",
              defaultBg: "#E7F9ED",
              defaultColor: "#0CBF42",
            }),
            ...(props.subtle && {
              defaultBorderColor: "#E7F4FD",
              defaultBg: "#E7F4FD",
              defaultColor: "#0C8CE9",
            }),
          },
        },
      }}
    >
      <AntButton {...props} loading={loading} />
    </ConfigProvider>
  );
}
