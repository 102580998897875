import React from "react";
import { Dropdown as AntDropdown, Space } from "antd";

import clsx from "clsx";
import Button from "components-shared/Button/Button";

import { RiArrowDropDownLine } from "react-icons/ri";

export default function Dropdown({
  items,
  label,
  onClick,
  trigger,
  disable,
  loading = false,
}) {
  return (
    <AntDropdown
      menu={{ items, onClick }}
      placement="bottomRight"
      trigger={trigger}
    >
      <Button
        shape="round"
        size="large"
        className={clsx("flex items-center font-bold")}
        disabled={disable}
        loading={loading}
      >
        <Space>{label}</Space>
        <RiArrowDropDownLine size={24} />
      </Button>
    </AntDropdown>
  );
}
