import axios from "axios";

import config from "config/config";
import { getAuthToken, handleLogout } from "utils/helpers";
import { postRefreshToken } from "api/auth";
import { message } from "antd";
import { Exception } from "sass";
import { handleError } from "utils/globalErrorhandler";

const BASE_URL = config.apiBaseUrl;
let authToken = null;

const isTokenExpired = (token) => {
  if (!token) return true;

  const [, payloadBase64] = token.split(".");
  const payload = JSON.parse(atob(payloadBase64));
  const expiryTime = payload.exp * 1000;
  const currentTime = Date.now();

  return currentTime > expiryTime;
};

/* Adjust authorization according to API */
const Client = axios.create({
  baseURL: BASE_URL,
  timeout: 120000,
  // withCredentials: true,
  headers: {
    Authorization: `Bearer ${getAuthToken()}`,
  },
});

// Client.interceptors.request.use(
//   (config) => {
//     config.headers["Authorization"] = authToken
//       ? `${authToken}`
//       : "bearer request";
//     return config;
//   },
//   (error) => {
//     return Promise.reject(error);
//   }
// );

// Client.interceptors.response.use(
//   (response) => {
//     const newToken = response.headers["authorization"];
//     if (newToken) {
//       authToken = newToken;
//     }
//     return response;
//   },
//   (error) => {
//     return Promise.reject(error);
//   }
// );

Client.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    handleError(error);

    const originalRequest = error.config;
    if (error.response?.status === 403 && !originalRequest._retry) {
      originalRequest._retry = true;
      const refreshToken = localStorage.getItem("refreshToken");
      if (refreshToken) {
        try {
          const response = await postRefreshToken({
            refreshToken: refreshToken,
          });
          const newAccessToken = response.data.data.token;

          localStorage.setItem("authToken", newAccessToken);
          originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
          return axios(originalRequest);
        } catch (error) {
          handleLogout();
        }
      } else {
        handleLogout();
      }
    }
    return Promise.reject(error);
  }
);

export const customGet = (path) => {
  return axios.get(path);
};

export const getRequest = (path, query, responseType = "json") => {
  return Client.get(path, {
    params: query,
    responseType: responseType,
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const getRequestWithBody = (path, body, responseType = "json") => {
  return Client.get(path, {
    body: body,
    responseType: responseType,
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const postRequestMultiPart = (path, data) => {
  return Client.post(path, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const postRequest = (path, data) => {
  return Client.post(path, data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const patchRequest = (path, data) => {
  return Client.patch(path, data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const multiGetRequest = async (paths) => {
  let arr = [];

  paths.forEach((item) => {
    arr.push(Client.get(`${BASE_URL}${item}`, {}));
  });
  return axios.all(arr);
};

export const multiPostRequest = async (configs) => {
  let arr = [];
  configs.forEach((item) => {
    arr.push(Client.post(`${BASE_URL}${item.url}`, item.data, {}));
  });
  return axios.all(arr);
};

export const putRequest = (path, data) => {
  return Client.put(path, data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const headRequest = (path) => {
  return Client.head(path, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const deleteRequestInBody = (path, body) => {
  return Client.delete(`${BASE_URL}${path}`, {
    headers: {
      "Content-Type": "application/json",
    },
    data: body,
  });
};

export const deleteRequest = (path, params) => {
  return Client.delete(path, {
    headers: {
      "Content-Type": "application/json",
    },
    params: params,
  });
};
