import "./index.scss";
import "react-toastify/dist/ReactToastify.min.css";

import { BrowserRouter, useLocation } from "react-router-dom";

import { ErrorBoundary } from "react-error-boundary";
import Navbar from "components-layout/Navbar";

import { AppContextProvider, useAppContext } from "contexts/AppContext";
import Footer from "components-layout/Footer/Footer";

import AppRouter from "./AppRouter";

import { GoogleOAuthProvider } from "@react-oauth/google";
import config from "config/config";

import { motion } from "framer-motion";
import clsx from "clsx";
import Error from "components-shared/ErrorBoundary/Errorboundary";
import useGlobalEffects from "./useGlobalEffect";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Spin } from "antd";
import TimeoutError from "components-shared/ErrorBoundary/TimeoutError";
import NetworkStatus from "utils/networkStatus";

function AppWrapper() {
  const location = useLocation();

  const pattern = /^\/resume\/[0-9a-f]{24}$/;
  useGlobalEffects();

  const { loading, state } = useAppContext();

  return (
    <>
      {/* <GoogleOAuthProvider clientId={config.googleClientId}> */}
      <Spin spinning={state.isLoading} className={"min-h-[60vh]"}>
        <Navbar />
        <div className="min-h-[90vh]">
          <AppRouter />
        </div>

        {!pattern.test(location.pathname) && <Footer />}
      </Spin>

      {/* </GoogleOAuthProvider> */}
    </>
  );
}

function App() {
  return (
    <>
      {/* <NetworkStatus /> */}
      <ErrorBoundary
        fallback={({ error }) => {
          if (
            error?.code === "ECONNABORTED" ||
            error?.message.includes("timeout")
          ) {
            return <TimeoutError />;
          }
          return <Error />;
        }}
      >
        <motion.div
          animate={{ y: 0, height: 0 }}
          transition={{ ease: "easeOut", duration: 1 }}
          className={clsx("absolute h-screen w-screen bg-white z-[1]")}
        ></motion.div>
        <BrowserRouter>
          <GoogleOAuthProvider clientId={config.googleClientId}>
            <AppContextProvider>
              <AppWrapper />
            </AppContextProvider>
          </GoogleOAuthProvider>
        </BrowserRouter>
      </ErrorBoundary>
    </>
  );
}

export default App;
